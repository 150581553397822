import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Chip,
} from '@material-ui/core'
import { TextSm } from '@system'
import get from 'lodash/get'

const useStyles = makeStyles((theme) => ({
  filter: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.text.secondary,
    },
    '& .MuiFormLabel-filled': {
      display: 'block',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(12px, 6px) scale(1)',
      [theme.breakpoints.up('md')]: {
        transform: 'translate(12px, 7px) scale(1)',
      },
      [theme.breakpoints.up('lg')]: {
        transform: 'translate(12px, 8px) scale(1)',
      },
    },
    '& label': {
      width: 'calc(100% - 15px)',
      '& p': {
        whiteSpace: 'nowrap',
        width: 'calc(100% - 23px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  filterBackgroundOpacity: {
    opacity: '0 !important',
  },
  filterInput: {
    height: '32px',
    marginTop: '0px !important',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    '&>div': {
      paddingTop: '4px',
      paddingBottom: '4px',
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&>svg': {
      fontSize: '1.5rem',
      color: theme.palette.background.slate,
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      paddingLeft: '14px',
      opacity: '0',
      whiteSpace: 'initial',
    },
  },
  textField: {
    '& input': {
      padding: '7px 10px',
      height: '32px',
      boxSizing: 'border-box',
    },
  },
  check: {
    marginTop: '0px',
  },
  multiSelectFilterChip: {
    position: 'absolute',
    zIndex: '1',
    pointerEvents: 'none',
    height: '20px',
    width: '20px',
    right: '9px',
    fontSize: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&>span': {
      padding: '0',
      fontSize: '9px',
    },
  },
  display: {
    display: 'none',
  },
  option: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const MultiSelectFilter = (props) => {
  const {
    title,
    filterDataPath,
    filterResultsLogic,
    multipleValuesLogic,
    filters,
    onChangeFilters,
    updateFilterLogicRules,
  } = props.blok
  const classes = useStyles()

  const onChangeFilter = (event) => {
    onChangeFilters(event, filterDataPath)
    updateFilterLogicRules(
      filterResultsLogic,
      multipleValuesLogic,
      filterDataPath
    )
  }

  const fieldOptions = get(props.blok, 'options.[0].fieldOptions', [])
  const values = filters.filter(
    (filter) => filter.filterBy.toLowerCase() === filterDataPath.toLowerCase()
  )

  const numOfFiltersSelected =
    values.length > 0
      ? values.reduce((acc, value) => acc).filterSelect.length
      : 0

  return (
    <SbEditable content={props.blok}>
      <FormControl variant="outlined" className={classes.filter}>
        {numOfFiltersSelected && numOfFiltersSelected > 0 ? (
          <>
            <InputLabel htmlFor={title}>
              <TextSm>{title}</TextSm>
            </InputLabel>
            <Chip
              color="primary"
              label={`${numOfFiltersSelected}`}
              className={classes.multiSelectFilterChip}
            ></Chip>
          </>
        ) : (
          <InputLabel htmlFor={title}>
            <TextSm>{title}</TextSm>
          </InputLabel>
        )}
        <Select
          multiple
          name={filterDataPath}
          value={
            filters.length === 1 && filters[0].filterBy === ''
              ? []
              : values.length > 0
              ? values.reduce((acc, value) => acc).filterSelect
              : []
          }
          onChange={onChangeFilter}
          input={<Input disableUnderline />}
          className={classes.filterInput}
        >
          {!!fieldOptions &&
            fieldOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <TextSm className={classes.option}>{option}</TextSm>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </SbEditable>
  )
}
export default MultiSelectFilter
